import React, { Component } from "react";
import { StaticQuery, graphql, Link } from "gatsby";

import Section from "react-bulma-components/lib/components/section/section";
import Container from "react-bulma-components/lib/components/container/container";
import Columns from "react-bulma-components/lib/components/columns/columns";
import Column from "react-bulma-components/lib/components/columns/components/column";
import Heading from "react-bulma-components/lib/components/heading/heading";
import Content from "react-bulma-components/lib/components/content/content";

import Styles from "./index.module.scss";
import SketchBorderSrc from "../../components/section-separator/sketch-border-dark.svg";


export default class Footer extends Component {
	renderContent ({ path, headline, subline }) {
		return (
			<React.Fragment>
				<Section id={ path } className={ Styles.section }>
					<Container className={ "has-text-white" }>
						<Heading
							renderAs={ "h1" }
							className={ Styles.heading }>
							{ headline }
						</Heading>

						<Content
							className={ Styles.subline }
							size={ "medium" }>
							{ subline }
						</Content>
					</Container>
				</Section>

				<Section className={ Styles.sectionFooterNav }>
					<img
						src={ SketchBorderSrc }
						className={ Styles.sketchSeparator }
						alt=""
					/>

					<Container className={ "has-padding-bottom-lg" }>
						<Columns className={ Styles.navColumns }>
							<Column narrow>
								<p>
									<Link className={ Styles.link } to={ "/imprint" }>
										Impressum
									</Link>
									<span className={ "has-text-white has-text-weight-bold" }> / </span>
									<Link className={ Styles.link } to={ "/data-privacy" }>
										Datenschutz
									</Link>
								</p>
							</Column>

							<Column narrow className={ Styles.columnLeft }>
								<p className={ "has-text-white" }>
									© Coderwelsch – Coding & Design
								</p>
							</Column>
						</Columns>
					</Container>
				</Section>
			</React.Fragment>
		);
	}

	render () {
		return (
			<StaticQuery query={ graphql`
				query {
			        mr: markdownRemark(frontmatter: { path: { eq: "footer" } }) {
			            fm: frontmatter {
			            	path
			                headline
			                subline
			            }
			        }
			    }` }
	            render={ ({ mr }) => this.renderContent(mr.fm) }
			/>
		);
	}
}
