import React from "react";
import Helmet from "react-helmet";


const Seo = ({ title, meta }) =>
	<Helmet title={ title }>
		{ meta.map(data =>
			<meta
				key={ data.name }
				{ ...data }
			/>
		) }
	</Helmet>;

export default Seo;
